import "@hotwired/turbo-rails"
Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()

import './application.scss'
import '../controllers'

const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'
